import React from 'react'

function RepaymentProgress({ userProfile }) {
    if (!userProfile || !userProfile[0]) {
        return null
    }
    const progress = userProfile[0].number_of_payments
    const filledBars = Array.from({ length: progress }, (_, i) => (
        <div
            key={`filled-${i}`}
            className="bg-[#D882AF] h-1 rounded-sm"
            style={{ gridColumnStart: i + 1 }}
        />
    ))

    const unfilledBars = Array.from({ length: 12 - progress }, (_, i) => (
        <div
            key={`unfilled-${i}`}
            className="bg-gray-300 h-1 rounded-sm"
            style={{ gridColumnStart: progress + i + 1 }}
        />
    ))
    const userInfo = userProfile[0]
    const dateString = new Date(userProfile[0].disbursement_date)
    const formattedDate = dateString.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-2 mb-5 text-xl font-bold text-left">
            <div>
                <h1>
                    Personal Loan (****{userProfile[0]?.loan_id?.slice(-4).toUpperCase()})
                </h1>{' '}
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="text-left font-light">
                    <h2 className="text-sm">Loan Status</h2>
                    <h2 className="text-sm">APR</h2>
                    <h2 className="text-sm">Dispursement Date</h2>
                </div>
                <div className="text-right font-light">
                    <h2 className="text-sm capitalize">
                        {userInfo.loan_status}
                    </h2>
                    <h2 className="text-sm">
                        {(userInfo.apr / 100).toFixed(2)}%
                    </h2>
                    <h2 className="text-sm">{formattedDate}</h2>
                </div>
            </div>
            <div className="mt-2 mb-2">
                <h1>Repayment Progress</h1>
            </div>

            <div className="grid grid-cols-12 gap-1 h-4 bg-transparent rounded mt-1">
                {filledBars}
                {unfilledBars}
            </div>

            <div className="text-sm font-normal text-[#9A9A9A]">
                <p className="text-right">${userInfo.total_amount / 100}</p>
            </div>
            {/* <div className="text-blue-600 text-center text-sm font-semibold">
                <a>Further Loan Details</a>
                /* show further loan details */}
        </div>
    )
}

export default RepaymentProgress
