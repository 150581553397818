import React from 'react'

function PaymentInfoCard({ onPress, open, ready, userProfile }) {
    const paymentAmount = userProfile[0].next_payment_amount / 100
    const num = userProfile[0]?.plaid_account_details?.account
    const lastFourDigits = num?.slice(-4) ?? ''
    const dateString = userProfile[0].next_payment_due_date
    const date = new Date(dateString)
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })

    const accountDisplay = userProfile[0]?.plaid_account_information
        ? userProfile[0].plaid_account_information.name.substring(0, 5) +
          '****' +
          lastFourDigits
        : 'Connect Bank Account'

    const account = userProfile[0]?.plaid_account_information

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 lg:w-[480px]">
            <h1 className="font-bold text-xl mb-2 text-left">Payment Info</h1>
            <div className="grid grid-cols-2 gap-4">
                <div className="text-left">
                    <h2 className="text-md mb-2">Due Date</h2>
                    <h2 className="text-md mb-2">Minimum Amount Due</h2>
                    <h2 className="text-md mb-2">Account</h2>
                    <h2 className="text-md">Payment Status</h2>
                </div>
                <div className="text-right">
                    <h2 className="text-md font-semibold mb-2">
                        {formattedDate}
                    </h2>
                    <h2 className="text-md font-semibold mb-2">
                        ${paymentAmount}
                    </h2>
                    <h2 className="text-md font-semibold mb-2">
                        {account ? accountDisplay : 'Connect Bank Account'}
                    </h2>
                    <h2 className="text-md font-semibold capitalize">
                    {userProfile[0].payment_status ? userProfile[0].payment_status : "Unpaid"}
                    </h2>
                </div>
            </div>

            <button
                className={`${
                    userProfile[0].payment_status === 'Submitted'
                        ? 'bg-gray-500'
                        : 'bg-[#33334E]'
                } text-white font-bold rounded h-[48px] w-full mt-5`}
                onClick={() => (window.location.href = '/payments')}
                disabled={userProfile[0].payment_status === 'Submitted'}
            >
                {userProfile[0].payment_status === 'Submitted'
                    ? 'Payment Submitted'
                    : account
                    ? 'Pay Now'
                    : 'Connect Bank Account to Pay'}
            </button>
        </div>
    )
}

export default PaymentInfoCard
