import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const NavBar = ({ session, userProfile }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <nav className="fixed top-0 left-0 w-full flex justify-between items-center py-4 px-6">
      <Link
        to="/"
        className={`text-4xl font-bold ${
          session ? 'text-black' : 'text-white'
        }`}
      >
        Marley
      </Link>
      {session && userProfile ? (
        <div className="relative">
          <button
            className="button block"
            onClick={handleToggleDropdown}
            aria-expanded={showDropdown}
            aria-haspopup="true"
          >
            Hello, {userProfile[0].first_name} ▽
          </button>
          {showDropdown && (
            <div className="absolute bg-white shadow-md mt-2 rounded-md w-[155px]">
              <Link
                to="/manage-account"
                className="block text-left px-4 py-2"
                onClick={handleToggleDropdown}
              >
                Manage Account
              </Link>
              <button
                className="block w-full text-left px-4 py-2"
                onClick={() => supabase.auth.signOut()}
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      ) : null}
    </nav>
  );
};

export default NavBar;
