import React from 'react'
import PaymentDetails from '../components/PaymentDetails'

function PaymentPage({
    onPress,
    open,
    ready,
    accountDetails,
    accountInfo,
    userProfile,
}) {
    const paymentDetailsProps = {
        open,
        ready,
        accountDetails,
        accountInfo,
        userProfile,
    }

    return (
        <div>
            <div className="bg-white shadow-md rounded px-8 lg:pt-6 pb-8 mb-4 lg:w-[480px] lg:h-[550px]">
                <h1 className="font-bold text-xl mb-2 text-left">
                    Payment Info
                </h1>
                <PaymentDetails {...paymentDetailsProps} />
            </div>
        </div>
    )
}

export default PaymentPage
