import React, { useState } from 'react'
import { supabase } from '../../supabaseClient'

const EnableAutoPay = ({
    onPress,
    open,
    ready,
    accountDetails,
    accountInfo,
    userProfile,
}) => {
    const [isAgreed, setIsAgreed] = useState(false)
    const paymentAmount = userProfile[0].next_payment_amount / 100
    const [autoPayEnabled, setAutoPayEnabled] = useState(false)
    const num = userProfile[0]?.plaid_account_details?.account

    const handleAgreement = (event) => {
        setIsAgreed(event.target.checked)
    }

    const enableAutoPay = async () => {
        const { data, error } = await supabase
            .from('profiles')
            .update({ auto_pay: true })
            .eq('id', userProfile[0].id)

        if (error) {
            console.log('Error updating auto_pay:', error)
        } else {
            setAutoPayEnabled(true)
        }
    }

    const goHome = () => {
        window.location.href = '/'
    }

    return (
        <div>
            {num ? (
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 md:w-[500px]">
                    {!autoPayEnabled ? (
                        <>
                            <h2 className="text-2xl font-bold mb-4">
                                Setup Autopay
                            </h2>
                            <p className="text-sm">
                                Enabling Auto Pay will automatically deduct the
                                monthly payment amount of ${paymentAmount} on
                                each monthly due date from{' '}
                                {userProfile[0].plaid_account_information.name}
                            </p>

                            <div className="mt-5 mb-2">
                                <input
                                    type="checkbox"
                                    name="isAgreed"
                                    checked={isAgreed}
                                    onChange={handleAgreement}
                                    className="mr-4"
                                />
                                <label>
                                    I consent to ACH Authorization
                                </label>
                            </div>
                            <button
                                className="bg-[#33334E] text-white font-bold rounded h-[48px] w-full mt-5"
                                onClick={enableAutoPay}
                                disabled={!isAgreed}
                            >
                                Enable Auto Pay
                            </button>
                            <button
                                className="bg-grey text-grey font-bold rounded h-[30px] w-full mt-2"
                                onClick={goHome}
                            >
                                Do Not Enable Auto Pay
                            </button>
                        </>
                    ) : (
                        <>
                            <div className="m-4 text-green-600 font-semibold">
                                Autopay Enabled
                            </div>
                            <button
                                className="bg-[#33334E] border-gray text-white font-bold rounded h-[48px] w-full mt-2"
                                onClick={goHome}
                            >
                                Go Home
                            </button>
                        </>
                    )}
                </div>
            ) : (
                <div>
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[520px]">
                        <h1 className="font-bold text-xl mb-6 text-left">
                            Auto Pay Setup
                        </h1>
                        <p>
                            Auto Pay means future payments on your loan will be
                            deducted regularly from your Account. You can change
                            this anytime
                        </p>

                        <button
                            className="bg-[#33334E] text-white font-bold rounded h-[48px] w-full mt-5"
                            onClick={open}
                            disabled={!ready}
                        >
                            Connect Bank Account
                        </button>
                        <button
                            className="bg-[#33334E] text-white font-bold rounded h-[48px] w-full mt-5"
                            onClick={goHome}
                        >
                            Cancel Auto Pay Setup
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EnableAutoPay
