import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';

const EditAutoPay = ({
    onPress,
    open,
    ready,
    accountDetails,
    accountInfo,
    userProfile,
}) => {
    const num = userProfile[0].plaid_account_details.account;
    const lastFourDigits = num.slice(-4); // "7890"

    const dateString = userProfile[0].next_payment_due_date;
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
    const paymentAmount = userProfile[0].next_payment_amount / 100;

    const accountDisplay =
        userProfile[0].plaid_account_information.name.substring(0, 5) +
        '****' +
        lastFourDigits;
    const account = userProfile[0];

    const [autoPayUpdated, setAutoPayUpdated] = useState(false);

    const enableAutoPayUpdated = async () => {
        const { data, error } = await supabase
            .from('profiles')
            .update({ auto_pay: false })
            .eq('id', userProfile[0].id);

        if (error) {
            
        } else {
            setAutoPayUpdated(true);
        }
    };

    const goHome = () => {
        window.location.href = '/';
    };

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 md:w-[480px]">
            {!autoPayUpdated ? (
                <>
                    <h2 className="text-3xl font-bold mb-4">Edit Autopay</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="text-left">
                     
                            <h2 className="text-md mb-2">Account</h2>
                        </div>
                        <div className="text-right">
                        
                            <h2 className="text-md font-semibold mb-2">
                                {account ? accountDisplay : 'Connect Bank Account'}
                            </h2>
                        </div>
                    </div>
                    <div className="mt-5 grid grid-cols-1 gap-3">
                        <button
                            type="submit"
                            onClick={open}
                            disabled={!ready}
                            className="bg-[#33334E] text-white font-bold rounded h-[48px] w-full mt-1"
                        >
                            Connect New Bank Account
                        </button>
                        <button onClick={enableAutoPayUpdated}>Cancel Autopay</button>
                    </div>
                </>
            ) : (
                <>
                    <div className="m-4 text-green-600 font-semibold">
                        Autopay Cancelled
                    </div>
                    <button
                        className="bg-[#33334E] text-white font-bold rounded h-[48px] w-full mt-2"
                        onClick={goHome}
                    >
                        Go Home
                    </button>
                </>
            )}
        </div>
    );
};

export default EditAutoPay;
