import React from 'react'

function AutoPay({ userProfile }) {
    const autopay = userProfile[0].auto_pay // hard code, get from account info
    const account = userProfile[0]?.plaid_account_information

    return (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-2 lg:w-[480px]">
            <h1 className="font-bold text-xl mb-2 text-left">Auto Pay</h1>
            <div className="grid grid-cols-2 gap-4">
                <div className="text-left">Status</div>

                <div className="text-right">
                    <h2 className="text-md mb-2 font-semibold">
                        {autopay === true ? 'Enabled' : 'Not Enabled'}
                    </h2>
                </div>
            </div>

            <button
                className="bg-[#33334E] text-white font-bold rounded h-[48px] w-full mt-5"
                onClick={() => (window.location.href = '/autopay')}
            >
                {!account
                    ? 'Setup Auto Pay'
                    : !autopay
                    ? 'Set Up Auto Pay'
                    : 'Edit Auto Pay Information'}
            </button>
        </div>
    )
}

export default AutoPay
