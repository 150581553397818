import React from 'react'
import MyAccountCard from '../components/cards/MyAccountCard'
import PaymentsCard from '../components/cards/PaymentsCard'

const ManageAccount = ({ userProfile }) => {
    return (
        <div className="container mx-auto mt-16">
            <MyAccountCard userProfile={userProfile} />
            <PaymentsCard color="light" userProfile={userProfile} />
        </div>
    )
}

export default ManageAccount
