import './App.css'
import { useState, useEffect } from 'react'
import PaymentInfoCard from './components/PaymentInfoCard'
import axios from 'axios'
import { usePlaidLink } from 'react-plaid-link'
import PaymentDetails from './components/PaymentDetails'
import RepaymentProgress from './components/RepaymentProgress'
import Login from './components/Login'
import { supabase } from './supabaseClient'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import PaymentPage from './pages/PaymentPage'
import NavBar from './components/NavBar'
import ManageAccount from './pages/ManageAccount'
import AutoPay from './components/Autopay'
import AutoPayPage from './pages/AutopayPage'

function PlaidAuth({
    publicToken,
    onAccountDetailsFetched,
    onAccountInfoFetched,
}) {
    const [account, setAccount] = useState()

    useEffect(() => {
        async function fetchData() {
            let accessToken = await axios.post('/exchange_public_token', {
                public_token: publicToken,
            })
            const auth = await axios.post('/auth', {
                access_token: accessToken.data.accessToken,
            })
            setAccount(auth.data.numbers.ach[0])

            onAccountDetailsFetched(auth.data.numbers.ach[0])
            onAccountInfoFetched(auth.data.accounts[0])
        }
        fetchData()
    }, [])

    return null
    // return account && (<>
    // <p>Account Number: {account.account}</p>
    // <p>Routing Number: {account.routing}</p>
    // </>);
}

//todo- add progress bar
function App() {
   axios.defaults.baseURL = 'https://marley-backend.herokuapp.com' //production
   // axios.defaults.baseURL = 'https://marley-server-sandbox.herokuapp.com' //sandbox
    // axios.defaults.baseURL = 'http://localhost:3000'

    const [linkToken, setLinkToken] = useState()
    const [publicToken, setPublicToken] = useState()
    const [paymentStep, setPaymentStep] = useState(0)
    const [accountDetails, setAccountDetails] = useState(null)
    const [accountInfo, setAccountInfo] = useState(null)
    const [userProfile, setUserProfile] = useState(null)
    const [session, setSession] = useState(null)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
            if (session) {
                fetchUserProfile(session.user.id)
            }
        })

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
            if (session) {
                fetchUserProfile(session.user.id)
            } else {
                setUserProfile(null) // Set userProfile to null if no session
            }
        })
    }, [])

    async function fetchUserProfile(id) {
        const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', id)

        if (error) {
            console.error('Error fetching user profile:', error)
        } else {
            setUserProfile(data)
        }
    }

    async function updatePlaidAccountInformation(userId, accountInfo) {
        const { error } = await supabase
            .from('profiles')
            .update({
                plaid_account_information: accountInfo,
            })
            .eq('id', userId)

        if (error) {
            console.error('Error updating Plaid account information:', error)
        } else {
            window.location.reload()
        }
    }

    async function updateAccountDetails(userId, accountDetails) {
        const { error } = await supabase
            .from('profiles')
            .update({
                plaid_account_details: accountDetails,
            })
            .eq('id', userId)

        if (error) {
        } else {
        }
    }

    useEffect(() => {
        async function fetch() {
            const response = await axios.post('/create_link_token')
            setLinkToken(response.data.link_token)
        }
        fetch()
    }, [])

    const completePaymentStep = () => {
        setPaymentStep((cur) => cur + 1)
    }

    const onAccountDetailsFetched = (accountDetails) => {
        setAccountDetails(accountDetails)
        if (session && session.user) {
            updateAccountDetails(session.user.id, accountDetails)
        }
    }

    const onAccountInfoFetched = (accountInfo) => {
        setAccountInfo(accountInfo)
        if (session && session.user) {
            updatePlaidAccountInformation(session.user.id, accountInfo)
        }
    }

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            setPublicToken(public_token)
            // send public_token to server
        },
    })

    return (
        <Router>
        <div className="App min-h-screen flex flex-col">
          <NavBar session={session} userProfile={userProfile} />
          {!session ? (
            <Login />
          ) : (
            userProfile && (
              <>
                <div className="flex-1 flex mx-auto items-center justify-center">
                  <div className="max-w-[550px] w-full px-4 md:px-8 mt-[80px]">
                    <Switch>
                      <Route exact path="/">
                        <section>
                          <div className="flex flex-col text-black">
                            <div className="w-full mx-auto text-center">
                              <RepaymentProgress userProfile={userProfile} />
                              <PaymentInfoCard
                                onPress={completePaymentStep}
                                userProfile={userProfile}
                              />
                            </div>
                            <AutoPay userProfile={userProfile} />
                          </div>
                        </section>
                      </Route>
                      <Route path="/manage-account">
                        <ManageAccount userProfile={userProfile} />
                      </Route>
                      <Route path="/payments">
                        <PaymentPage
                          open={open}
                          ready={ready}
                          accountDetails={accountDetails}
                          accountInfo={accountInfo}
                          userProfile={userProfile}
                        />
                      </Route>
                      <Route path="/autopay">
                        <AutoPayPage
                          open={open}
                          ready={ready}
                          accountDetails={accountDetails}
                          accountInfo={accountInfo}
                          userProfile={userProfile}
                        />
                      </Route>
                    </Switch>
                    {publicToken && (
                      <PlaidAuth
                        publicToken={publicToken}
                        onAccountDetailsFetched={onAccountDetailsFetched}
                        onAccountInfoFetched={onAccountInfoFetched}
                      />
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </Router>
    );
          
}

export default App
