import React from 'react'
import EnableAutoPay from '../components/autopay-setup/EnableAutoPay'
import EditAutoPay from '../components/autopay-setup/EditAutoPay'

const AutoPayPage = ({
    onPress,
    open,
    ready,
    accountDetails,
    accountInfo,
    userProfile,
}) => {
    const autoPayProps = {
        open,
        ready,
        accountDetails,
        accountInfo,
        userProfile,
    };

   const account = userProfile[0].plaid_account_details
   const auto_pay = userProfile[0].auto_pay
    return (
        <div className="container mx-auto ">
            {auto_pay === false ? ( <EnableAutoPay {...autoPayProps}/>) : (<div> </div>)}
            {account && auto_pay === true ? ( <EditAutoPay {...autoPayProps}/>) : (<div> </div>)}
          
        </div>
    )
}

export default AutoPayPage
